body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: white;
}

.Main-grid {
  display: grid;

  grid-template-columns: 1fr 4fr;

  margin-right: 35px;

  align-items: start;
}

.Side-bar {
  display: flex;

  flex-direction: column;

  margin: 15px 35px 0 50px;
  padding: 25px 20px 0 20px;

  border: #d2d2d2 solid 2px;
  border-radius: 10px;

  box-shadow: 0 1px 1px #d2d2d2;
}

.Side-bar > SortGroup,
.Side-bar > div {
  margin-bottom: 15px;
}

.Item-grid {
  display: grid;
  margin: 15px;
  grid-gap: 30px;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: 1fr;
}

@media screen and (max-width: 768px) {
  .Item-grid {
    grid-template-columns: 1fr;
  }
}
